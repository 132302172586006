import React from "react";
import ImageMeta from "../../components/ImageMeta";

import TheaterVideo from "@components/Theater/TheaterVideo";
import TextPhoto from "../../components/TextPhoto/TextPhoto";
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer";

var classNames = require("classnames");

function Body(props) {


  if (props.post.hasBodyImages) {
    return (
      <>
        <div
          className="body-section about-doc-block"
        >
          <div className="columns">
            <div className="column is-2"></div>
            <MarkdownViewer
              className="column"
              markdown={props.post.aboutSection.body}
            />
            <div className="column is-2"></div>
          </div>
          {props.post.aboutSection.youtube && (
            <div className="columns">
              <div className="column has-text-centered">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${props.post.aboutSection.youtube}`}
                  controls={true}
                  playing={true}
                  overVideo={false}
                  onlyButton={true}
                  language={props.language}
                  buttonClass="contained"
                ></TheaterVideo>
              </div>
            </div>
          )}
        </div>

      {props.post.educationResidency.hasThisSection && <DoctorSection section={props.post.educationResidency} />}
      {props.post.certifications.hasThisSection && <DoctorSection section={props.post.certifications} />}

      {props.post.hobbiesInterests.hasThisSection 
        ? <DoctorSection section={props.post.hobbiesInterests} />
        : <TextOnlySection text={props.post.hobbiesInterests.textObject.body} />
      }

      </>
    )    
  }


  return (
    <>
      <section>
        <div className="columns">
          <div className="column is-4"></div>
          <div className="column doctor-text-only-rows">

            <div className="doc-about">
              {/* <ImageMeta
                cloudName="nuvolum"
                publicId="KTOMS/DEV/line"
                width="auto"
                className="stripe"
                responsive
              /> */}
              {<MarkdownViewer markdown={props.post.aboutSection.body} />}
              {/* <ImageMeta
                cloudName="nuvolum"
                publicId="KTOMS/DEV/line"
                width="auto"
                className="stripe"
                responsive
              /> */}
            </div>

            {props.post.educationResidency && props.post.educationResidency.textObject.body && <MarkdownViewer markdown={props.post.educationResidency.textObject.body} />}

            {props.post.certifications &&  props.post.certifications.textObject.body && <MarkdownViewer markdown={props.post.certifications.textObject.body} />}

            {props.post.hobbiesInterests &&  props.post.hobbiesInterests.textObject.body && <MarkdownViewer markdown={props.post.hobbiesInterests.textObject.body} />}
          </div>
          <div className="column is-4"></div>
        </div>
      </section>
    </>
  )
}

function DoctorSection(props) {

  let section = props.section;

  var sectionClasses = classNames({
    "body-section doc-bio": true,
    "text-first": section.textOrPhotoFirst === "text",
    "photo-first": section.textOrPhotoFirst === "photo",
  })

  return(
    <div className={sectionClasses}>
          <div className="columns is-vcentered">
            <div
              className={`column is-${section.columnWidths.left}`}
            ></div>

              {section.textObject.body && <MarkdownViewer
                className={`column first-text is-${section.textObject.width}`}
                markdown={section.textObject.body}
              />}

              <div
                className={`column first-image is-${section.imageObject.width}`}
              >
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={section.imageObject.imageId}
                  width="auto"
                  responsive
                ></ImageMeta>
              </div>

            <div
              className={`column is-${section.columnWidths.center}`}
            ></div>


              {section.textObject.body && <MarkdownViewer
                className={`column last-text is-${section.textObject.width}`}
                markdown={section.textObject.body}
              />}

              <div
                className={`column last-image is-${section.imageObject.width}`}
              >
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={section.imageObject.imageId}
                  width="auto"
                  responsive
                ></ImageMeta>
              </div>

            <div
              className={`column is-${section.columnWidths.right}`}
            ></div>
          </div>
        </div>
  )
}

function TextOnlySection(props) {
  return (
    <div className="columns text-only-doc-info">
      <div className="column is-2"></div>
      <div className="column">
        <MarkdownViewer markdown={props.text} />
      </div>
      <div className="column is-2"></div>
    </div>
  )
}

export default Body;
